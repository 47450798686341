import React from 'react';
import { useCallback } from 'react';

import * as Styles from './styles';

interface ITextInputProps {
  onChangeText?(event: React.ChangeEvent): void;
  placeholder: string;
  name: string;
  label?: string;
  disabled?: boolean;
  className?: string;
}
export const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  function TextInput(
    {
      children,
      onChangeText,
      disabled = false,
      label,
      name,
      className = '',
      placeholder,
      ...props
    },
    ref,
  ) {
    return (
      <Styles.TextInput
        placeholder={placeholder}
        className={`thr-text-input ${className}`}
        name={name}
        ref={ref}
        onChange={onChangeText}
        {...props}
      />
    );
  },
);
