import styled from 'styled-components';

import theme from '@/theme';

export const TextInput = styled.input`
  height: 45px;
  border: solid 1px;
  border-radius: 5px;
  background-color: white;
  color: black;
  margin-bottom: ${theme.space[3]}px;
  font-family: ${theme.fontFamily.regular};
  font-size: ${theme.fontSizes[1]}px;
  margin-bottom: ${theme.space[3]};

  :focus {
    outline: none;
  }
`;
