import styled from 'styled-components';

import theme from '::theme';
import Image from 'next/image';

export const AccessAccountLayout = styled.div`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#88ff88, #ffff00, #fffec6);
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  border: solid 1px black;
  border-radius: ${theme.borderRadius[3]}px;
  background-color: white;
  padding: ${theme.space[4]}px;
  z-index: 10;
`;

export const Container = styled.div`
  @media screen and (max-width: 64em) {
    display: none;
  }
  position: fixed;
  height: 100vh;
  width: 50%;
  ${({ theme }) =>
    theme.rtl
      ? `
      transform: scaleX(-1) translateX(100%);
           `
      : `
      transform: translateX(100%);
  `};
  overflow: hidden;
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 64em) {
    display: none;
  }
`;

export const ThndrDescription = styled.div`
  color: black;
  font-size: 64px;
  line-height: 87px;
  font-weight: 800;
  @media screen and (max-width: 64em) {
    line-height: 65px;
    margin-bottom: 45px;
  }
  ${({ theme }) =>
    theme.rtl
      ? `
`
      : `
      font-family: var(--headers-bold);

`};
`;

export const Logo = styled.img`
  width: 200px;
  align-self: center;
  margin-bottom: 50px;
`;

export const Arrow = styled.img`
  direction: rtl;
  margin-top: 120px;
  max-width: 100%;
  max-height: 100%;
  padding-right: 50px;
  ${({ theme }) =>
    theme.rtl
      ? `
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
  `
      : `
  `};
`;

export const LanguageToggle = styled.a`
  text-align: center;

  color: ${({ theme }) => (theme.rtl ? 'var(--primary-color)' : 'white')};
  font-size: 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
