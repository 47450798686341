import React from 'react';

import * as Styles from './styles';

interface ILoadingProps {
  loading?: boolean;
  size?: number;
  color?: string;
}
export const Loading: React.FC<ILoadingProps> = function Loading({
  loading,
  size,
  color,
}) {
  return (
    <Styles.Loading size={size} color={color}>
      <div />
      <div />
      <div />
    </Styles.Loading>
  );
};
