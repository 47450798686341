import styled from 'styled-components';

import theme from '@/theme';

type StyledButtonProps = {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  styleType?: string;
};

export const Button = styled.button<StyledButtonProps>`
  flex-direction: row;
  height: 45px;
  min-width: 170px;
  display: inline-block;
  border-radius: ${theme.borderRadius[1]}px;
  background-color: ${({ styleType, backgroundColor }) =>
    backgroundColor
      ? backgroundColor
      : styleType === 'secondary'
      ? 'var(--color-base1)'
      : 'black'};
  border: solid 0.5px transparent;
  border-color: ${({ borderColor }) =>
    borderColor ? borderColor : 'transparent'};
  color: ${({ textColor, styleType }) =>
    textColor
      ? textColor
      : styleType === 'secondary'
      ? 'var(--color-text-dark)'
      : theme.colors.base0};
  font-family: ${theme.fontFamily.regular};
  margin-top: ${theme.space[2]}px;
  margin-bottom: ${theme.space[2]}px;
  font-weight: ${theme.fontWeights.body};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  cursor: pointer;
`;

export const ButtonIcon = styled.img`
  margin-right: ${theme.space[2]}px;
  margin-left: ${theme.space[2]}px;
  vertical-align: text-bottom;
`;
