import styled from 'styled-components';

import theme from '@/theme';

type StyledLoadingProps = {
  size?: number;
  color?: string;
};

export const Loading = styled.div<StyledLoadingProps>`
  display: inline-block;
  position: relative;
  margin-top:7px;
  width: ${({ size }) => (size ? size : 50)}px;
  height: ${({ size }) => (size ? size : 50)}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => (size ? size : 50)}px;
    height: ${({ size }) => (size ? size : 50)}px;
    border:  ${({ size }) => (size ? size / 7 : 7)}px solid ${({ color }) =>
  color ? color : 'var(--color-green)'};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) =>
      color ? color : 'var(--color-green)'} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
