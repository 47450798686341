import React, { ButtonHTMLAttributes } from 'react';

import { Loading } from '../Loading/Loading';
import * as Styles from './styles';

import { useEffects } from '@/store';
import theme from '@/theme';

interface IButtonProps {
  onPress?(event: React.MouseEvent): void;
  icon?: string;
  disabled?: boolean;
  loading?: boolean;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  behavior?: 'button' | 'submit' | 'reset';
  type?: 'secondary';
}
export const Button: React.FC<IButtonProps> = function Button({
  children,
  onPress,
  loading,
  disabled,
  behavior = 'button',
  icon,
  type,
  ...props
}) {
  return (
    <Styles.Button
      {...props}
      type={behavior}
      disabled={disabled}
      onClick={onPress}
      styleType={type}>
      <style jsx>{`
        .thr-button {
          padding: ${theme.space[2]}px;
        }
      `}</style>
      {loading ? (
        <Loading size={18} loading={true} color={theme.colors.base0} /> // base0 is white in dark and white themes
      ) : (
        <div>
          {icon ? (
            <Styles.ButtonIcon src={icon} height={17} width={17} />
          ) : null}
          {children}
        </div>
      )}
    </Styles.Button>
  );
};
