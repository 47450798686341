import router, { useRouter } from 'next/router';
// import Particle from './Particles';
import { Row, Col } from 'antd';
import { useTranslation } from 'next-i18next';

import * as Styles from './styles';
import Image from 'next/image';
import { useStates } from '::store';
import NewUserView from '@/components/account-access/NewUserView/NewUserView';
export default (props) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const {
    accountAccess: { isNewUser },
  } = useStates();

  return (
    <>
      <Styles.Container>
        <Image src="/thndrLogin.webp" objectFit="cover" layout="fill" />
      </Styles.Container>

      <Styles.AccessAccountLayout>
        <Row style={{ height: '100vh' }} align="middle" justify="center">
          <Col xs={22} sm={19} lg={8}>
            <Styles.Logo src="/ThndrLogo.png" />
            <Styles.ThndrDescription>
              {t('thndrDescription')}
            </Styles.ThndrDescription>
          </Col>
          <Col xs={0} sm={0} md={0} lg={6}>
            <Styles.Arrow src="/Arrow.png" />
          </Col>
          <Col xs={22} sm={12} lg={7}>
            <Styles.FormContainer>
              {isNewUser ? <NewUserView /> : props.children}
            </Styles.FormContainer>
          </Col>
        </Row>
        <Styles.LanguageToggle
          onClick={(e) => {
            e.preventDefault();
            router.replace(router.asPath, router.asPath, {
              locale: locale === 'en' ? 'ar' : 'en',
            });
          }}>
          {locale === 'ar' ? 'English' : 'العربية'}
        </Styles.LanguageToggle>
      </Styles.AccessAccountLayout>
    </>
  );
};
