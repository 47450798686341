import styled from 'styled-components';

import theme from '@/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QR = styled.img`
  margin-top: 10px;
  width: 250px;
  height: 250px;
`;

export const Subtitle = styled.h3`
  text-align: left;
  margin: 0 0 ${theme.space[3]}px 3px;
  color: ${theme.colors.textLight};
  font-weight: ${theme.fontWeights.light};
`;
